<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('policy_advocacy.policy_request') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="3">
            <b-form-group
            label-for="policy_title"
            >
            <template v-slot:label>
              {{ $t('policy_advocacy.policy_title') }}
            </template>
            <b-form-input
            id="policy_title"
            type="text"
            v-model="search.policy_title"
            ></b-form-input>
            </b-form-group>
          </b-col>
           <b-col xs="12" sm="12" md="3">
            <ValidationProvider name="Start date" vid="start_date" rules="''" v-slot="{ errors }">
              <b-form-group
                label-for="start_date">
                <template v-slot:label>
                  {{ $t('globalTrans.start_date') }}
                </template>
                <date-picker
                  id="start_date"
                  v-model="search.start_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </date-picker>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="3">
            <ValidationProvider name="End date" vid="end_date" rules="''" v-slot="{ errors }">
              <b-form-group
                label-for="end_date">
                <template v-slot:label>
                  {{ $t('globalTrans.end_date') }}
                </template>
                 <date-picker
                  id="end_date"
                  v-model="search.end_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </date-picker>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('policy_advocacy.policy_request') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                      >
                        <b-form-checkbox
                          :id="'checkbox-' + field.label_en"
                          v-model="field.show"
                          :name="'checkbox-' + field.label_en"
                          value=1
                          unchecked-value=0
                        >
                          {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                  </template>
                   <template v-slot:cell(submission_date)="data">
                    {{data.item.submission_date|dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-form-details variant="iq-bg-success mr-2" size="sm" @click="details(data.item)" class="action-btn status" v-b-tooltip.hover :title="$t('globalTrans.view')"><i class="ri-eye-fill"></i></b-button>
                    <b-button v-if="data.item.status === 1" v-b-modal.modal-forward variant="iq-bg-success" size="sm" class="action-btn status" @click="forward(data.item)" :title="$t('globalTrans.forward')"><i class="ri-share-forward-line"></i></b-button>
                    <template v-if="data.item.status < 1">
                      <b-button v-b-modal.modal-form variant="iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" v-b-tooltip.hover :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-dropdown id="dropdown-top" right size="sm" variant="primary" class="m-2">
                        <template slot="button-content">
                          <i class="ri-stack-line"></i>
                        </template>
                        <b-dropdown-item @click="Approval(data.item, 2)" href="#">{{$t('globalTrans.approve')}}</b-dropdown-item>
                        <b-dropdown-item  @click="Approval(data.item, 3)" href="#">{{$t('globalTrans.reject')}}</b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status === 1 && data.item.flag=== 2">{{$t('globalTrans.pending')}}</span>
                    <span class="badge badge-success" v-if="data.item.status === 2">{{$t('globalTrans.approved')}}</span>
                    <span class="badge badge-danger" v-if="data.item.status === 3">{{$t('globalTrans.rejected')}}</span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details  :item="item"/>
    </b-modal>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form  :item="item"/>
    </b-modal>
    <b-modal id="modal-forward" size="lg" :title="$t('globalTrans.forward')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Forward :items="itemData"/>
    </b-modal>
    <b-modal id="modal-approve" size="md" :title="$t('globalTrans.approve')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Approve :items="itemData"/>
    </b-modal>
    <b-modal id="modal-reject" size="md" :title="$t('globalTrans.reject')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Reject :items="itemData"/>
    </b-modal>
  </div>
</template>
<style scoped>
  table#table-transition-example .flip-list-move {
    transition: transform 1s;
  }
</style>
<script>
  import RestApi, { bftiResReportServiceBaseUrl } from '@/config/api_config'
  import { bftipolicyRequestReceivedList, bftiPolicyRequestApproveRejectApi } from '../../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import Form from './Form'
  import i18n from '@/i18n'
  import Details from './Details'
  import Forward from './Forward'
  import Approve from './Approve'
  import Reject from './Reject'

 const defaultColumn = [
    { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.policy_advocacy.policy_title, label_bn: i18n.messages.bn.policy_advocacy.policy_title, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.meeting_management.memo_no, label_bn: i18n.messages.bn.meeting_management.memo_no, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.policy_advocacy.focal_point_name, label_bn: i18n.messages.bn.policy_advocacy.focal_point_name, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
    { label_en: i18n.messages.en.policy_advocacy.focal_point_designation, label_bn: i18n.messages.bn.policy_advocacy.focal_point_designation, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.bfti.submission_date, label_bn: i18n.messages.bn.bfti.submission_date, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.globalTrans.status, label_bn: i18n.messages.bn.globalTrans.status, class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
    { label_en: i18n.messages.en.globalTrans.action, label_bn: i18n.messages.bn.globalTrans.action, class: 'text-center', show: '1', order: 8, thStyle: { width: '10%' } }
  ]
  export default {
    mixins: [ModalBaseMasterList],
    components: {
       Form, Details, Forward, Approve, Reject
    },
    data () {
      return {
        sortBy: '',
        search: {
          policy_title: '',
          start_date: '',
          end_date: '',
          status: 1,
          flag: 2,
          flag_check: true,
          limit: 10
        },
        item: '',
        items: '',
        sortDesc: true,
        sortDirection: 'desc',
        labelData: [],
        itemData: [],
        editItem: [],
        editItemId: 0
      }
    },
    created () {
      this.labelData = this.labelList
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      labelList: function () {
        const dataList = defaultColumn
        return dataList.map(item => {
          if (this.$i18n.locale === 'bn') {
            const data = { value: item.label_en, text: item.label_bn }
            return Object.assign({}, item, data)
          } else {
            const data = { value: item.label_en, text: item.label_en }
            return Object.assign({}, item, data)
          }
        })
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          const labelData = {}
          labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
          return Object.assign(item, labelData)
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'policy_title_bn' },
            { key: 'memo_no' },
            { key: 'name_bn' },
            { key: 'designation_bn' },
            { key: 'submission_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
             { key: 'serial' },
            { key: 'policy_title' },
            { key: 'memo_no' },
            { key: 'name' },
            { key: 'designation' },
            { key: 'submission_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      detailsTitle () {
        return this.$t('policy_advocacy.policy_request') + ' ' + this.$t('globalTrans.details')
      },
      formTitle () {
        return this.$t('policy_advocacy.policy_request')
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    methods: {
      forward (item) {
        this.itemData = item
      },
      edit (item) {
        this.item = item
      },
      Approval (item, type) {
        let msg = ''
        if (type === 1) {
          msg = window.vm.$t('globalTrans.approveMsg')
        } else {
          msg = window.vm.$t('globalTrans.rejectMsg')
        }
        window.vm.$swal({
          title: msg,
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.acceptReject(item.id, type)
          }
        })
      },
      searchData () {
        this.loadData()
      },
      acceptReject (id, type) {
        const params = {
          id: id,
          status: type
        }
      RestApi.getData(bftiResReportServiceBaseUrl, bftiPolicyRequestApproveRejectApi, params)
          .then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
              this.loadData()
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
         })
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(bftiResReportServiceBaseUrl, bftipolicyRequestReceivedList, params)
        if (result.success) {
          this.$store.dispatch('setList', result.data.data)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      details (item) {
        this.item = item
      }
    }
  }
</script>
